import { TJsonCollectionFilter } from "@components/Algolia/algolia.type";
import { IMinMax } from "@components/Algolia/Filters/CustomRangeSlider.component";
import { ISearchState } from "@shared/algolia/algolia.types";

export enum EDataLayerPageType {
	Cart = "CartPage",
	Category = "CategoryPage",
	CheckoutDeliveryHow = "CheckoutDeliveryHowPage",
	CheckoutDeliveryWhere = "CheckoutDeliveryWherePage",
	CheckoutPayment = "CheckoutPaymentPage",
	Help = "HelpPage",
	Home = "HomePage",
	MyAccount = "MyAccountPage",
	NotFound = "404NotFoundPage",
	OrderConfirmation = "OrderConfirmationPage",
	PostReview = "PostReviewPage",
	Product = "ProductPage",
	Search = "SearchResultsPage",
	Content = "ContentPage",
	Store = "StorePage",
	Stores = "StoresPage",
}

export enum EDataLayerEventType {
	AddToCart = "add_to_cart",
	AddToCartCarousel = "add_to_cart_carousel",
	PageReady = "page ready",
	CheckoutNextStep = "checkout continue to next step",
	CheckoutSelectOption = "checkout select option",
	CheckoutViewStep = "checkout view step",
	MenuLoginClick = "menu_login_click",
	MenuLoginPanelClick = "menu_login_panel_click",
	MenuLoginPanelDisplay = "menu_login_panel_display",
	OrderConfirmation = "order confirmation",
	PageView = "page view",
	PageViewProductArticle = "product page view article",
	ProductCheckAvailability = "product_check_availability",
	ProductClick = "product click",
	ProductImpressions = "product impressions",
	ProductNotifyMe = "product_notify_me",
	RemoveFromCart = "remove_from_cart",
	SearchSuggestion = "search.suggestion",
	SizeGuideClick = "sizeguide_click",
	SmartsizeOpen = "smartsize_open",
	SmartsizeSelectSize = "smartsize_select_size",
	WebsiteError = "website_error",
	PaymentAttempt = "payment_attempt",
	PaymentProceedConfirmation = "payment.proceed.confirmation",
}

export enum EDataLayerListType {
	CartPanelReco = "cartpanel_reco",
	CartReco = "cart_reco",
	NoResultReco = "noresult_reco",
	PdpViewed = "pdp_viewed",
	PdpXSell = "pdp_xsell",
}

export type TAppliedFilters = Pick<ISearchState, "refinementList" | "range"> & {
	initialPriceRange?: IMinMax;
};

export interface IProductImpression {
	position: number;
	id: string;
	supermodel: { id: string };
	model: { id: string };
	availability: {
		warehouse?: string;
	};
	price: {
		currency: string;
		original: string;
		current: string;
	};
	brand: string;
	list?: string;
}

export interface IBuildDataLayerResultPageArgs {
	content: ISearchState;
	initialPriceRange?: IMinMax;
	locale: string;
	canonical: string;
	categoryName?: string;
	breadcrumbs?: string;
	translationsFiltersColors?: TJsonCollectionFilter;
}

export interface IDataLayerFiltersArgs {
	content: ISearchState;
	locale: string;
	initialPriceRange?: IMinMax;
	translationsFiltersColors?: TJsonCollectionFilter;
}

export interface IDataLayerSearchQuery {
	term: string | string[];
	results: number;
}

export interface IDataLayerLayoutFormat {
	canonical: string;
	pageType: string;
	region?: string;
	gtmBreadcrumbs?: string;
	locale: string;
	additionalInfo?: Record<string, unknown>;
}

export interface IDataLayerLightLayoutFormat {
	pageType: string;
	locale: string;
	additionalInfo?: Record<string, unknown>;
}

export interface IDataLayerCrossSellingImpressions {
	productImpressions: IProductImpression[];
	event?: EDataLayerEventType;
}

export interface IDataLayerArticlePdpViewArticle
	extends Pick<IProductImpression, "brand" | "price" | "availability"> {
	canonical: string;
	id: number;
	EAN: string;
	supermodel: {
		id: string;
		lifestage: number;
	};
	model: {
		id: number;
		lifestage: number;
	};
	name: {
		en?: string;
	};
	size: {
		en: string;
		code: string;
	};
	lifestage: number;
	family: {
		code: number;
	};
	nature: {
		[x: string]: string | number;
	};
	description: {
		en: string;
	};
	URL: string;
	images?: { url: string }[];
}

export interface IDataLayerEventPdpViewArticle {
	event: EDataLayerEventType;
	products: {
		articles: IDataLayerArticlePdpViewArticle[];
	};
}

export interface IDataLayerEventSearchSuggestion {
	term: string;
	count: number;
	type: string;
	url: string;
}
