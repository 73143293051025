import { IGeolookGeolocation, IUserGeolocation } from "@shared/types/geolocation.types";
import { ECookieName, TCookieContextResponse, getCookie, setCookie } from "@utils/cookie.utils";
import axios from "axios";
import userGeolocation from "@config/misc";
import { IncomingMessage } from "node:http";
import { NextApiResponse } from "next";
import { formatPostalCode } from "./postalCode.utils";
import { removeWhiteSpaces } from "@shared/text/text.utils";

const PROVINCE_ID = "GEO_SUB1";
const COUNTRY_ID = "CAN";

export const getUserGeolocationFromCookies = async ({
	req,
	res,
}: {
	req?: IncomingMessage;
	res?: NextApiResponse;
} = {}): Promise<IUserGeolocation> => {
	const postalCode = removeWhiteSpaces(getCookie(ECookieName.PostalCodeGeo, { req }));

	if (!postalCode) {
		setUserGeolocationCookies(userGeolocation, { res });
		return userGeolocation;
	}
	const province = getCookie(ECookieName.ProvinceGeo, { req });
	const latitude = parseFloat(getCookie(ECookieName.LatitudeGeo, { req }));
	const longitude = parseFloat(getCookie(ECookieName.LongitudeGeo, { req }));

	if (!province || !latitude || !longitude) {
		const newUserGeolocation = (await getUserGeolocation(postalCode)) || userGeolocation;
		setUserGeolocationCookies(newUserGeolocation, { res });
		return newUserGeolocation;
	}

	return {
		postalCode,
		province: getCookie(ECookieName.ProvinceGeo, { req }) ?? userGeolocation.province,
		latitude: parseFloat(getCookie(ECookieName.LatitudeGeo, { req })) ?? userGeolocation.latitude,
		longitude:
			parseFloat(getCookie(ECookieName.LongitudeGeo, { req })) ?? userGeolocation.longitude,
	};
};

export const parseUserGeolocation = (userGeolocation: IGeolookGeolocation): IUserGeolocation => {
	const { postalCode, latitude, longitude, geoReferentialList } = userGeolocation;
	const province = geoReferentialList.find((geo) => geo.geoType === PROVINCE_ID)?.geoId ?? "";
	return { postalCode, province, latitude, longitude };
};

export const getUserGeolocation = async (
	postalCode: string
): Promise<IUserGeolocation | undefined> => {
	const errorMessage = "Error getting user geolocation";
	try {
		const response = await axios.get<IGeolookGeolocation[]>(
			`/api/geo/${COUNTRY_ID}?postalCode=${formatPostalCode(postalCode).replace(/\u00A0/, "+")}`
		);

		if (!response.data) return;

		return parseUserGeolocation(response.data[0]);
	} catch (error) {
		console.info(errorMessage, error);
	}
};

export const setUserGeolocationCookies = (
	userGeolocation: IUserGeolocation,
	res?: TCookieContextResponse
) => {
	setCookie(
		ECookieName.PostalCodeGeo,
		removeWhiteSpaces(userGeolocation.postalCode).toUpperCase(),
		res
	);
	setCookie(ECookieName.ProvinceGeo, userGeolocation.province ?? "", res);
	setCookie(ECookieName.LatitudeGeo, userGeolocation.latitude?.toString() ?? "", res);
	setCookie(ECookieName.LongitudeGeo, userGeolocation.longitude?.toString() ?? "", res);
};
