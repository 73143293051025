import { ISeoCflData } from "@components/ContentfulComponents/ComposeSeo/type";
import { ICollection } from "@shared/contentful/contentful.types";
import { IMenuSeoLink } from "@utils/seo.utils";

export enum EPageTypenames {
	Home = "PageHomeContent",
	Text = "PageTextOnly",
	MultiContent = "PageMultiContent",
	SingleStore = "PageSingleStore",
	Stores = "PageStores",
	Help = "PageHelp",
	Layout = "PageCategory",
	Error = "ServerPagesBlockOfContent",
}

export enum EComposeTypenames {
	Seo = "Seo",
	Page = "Page",
}

export interface IPageCflData<ContentData> {
	pageCollection: ICollection<IPageContent<ContentData>>;
}

export interface IPageContent<ContentData> {
	__typename: EPageTypenames;
	title: string;
	slug: string;
	slugEnglish: string;
	slugFrench: string;
	seo: ISeoCflData;
	content: ContentData;
}

export enum EContentfulPageType {
	SingleStore = "pageSingleStore",
	Content = "pageMultiContent",
	Help = "pageHelp",
	Stores = "pageStores",
	Homepage = "pageHomeContent",
	TextOnly = "pageTextOnly",
}

interface ISeoData {
	title: string;
	description: string;
	noIndex: boolean;
	noFollow: boolean;
}

export interface IContentPageProps<Data> extends Pick<ISeoData, "noIndex" | "noFollow"> {
	data: Data;
	metas: {
		metaTitle: string;
		metaDescription: string;
	};
	contentJson: Record<string, string>;
	absoluteUrl: string;
	menuSeoLinks?: IMenuSeoLink[];
	slug?: string;
	region?: string;
	preview?: boolean;
}

export interface IStaticPageQueryVariables {
	slug: string;
	locale: string;
	defaultLocale: string;
	otherLocale: string;
}
